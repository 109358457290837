<template>
<main id="main" class="site-main site-main-detail">
    <div class="postercard" v-if="packagedet">
        <div class="postercard__img">
            <img :src="urlPath+'uploads/media/package/thumbnail/600450_'+packagedet[0].featuredimage" :srcset="urlPath+'uploads/media/package/'+packagedet[0].featuredimage+'  1400w'" alt="image description">
        </div>
        <div class="postercard__fact">
            <ul>

                <li>
                    <span class="icon-price-tag"></span>
                    <dl>
                        <dt>Trip Price</dt>
                        <dd v-if="packagedet[0].discountedprice">{{packagedet[0].discountedprice}}</dd>
                        <dd v-else>{{packagedet[0].regularprice}}</dd>
                    </dl>
                </li>
                <template v-for="(highlight,index) in highlights" :key="index">
                    <li v-if="highlight.highlightname=='Difficulty'">
                        <span v-if="highlight.highlightname=='Difficulty'" class="icon-grade"></span>
                        <dl v-if="highlight.highlightname=='Difficulty'">
                            <dt>Difficulty</dt>
                            <dd>{{ highlight.highlightvalue }}</dd>
                        </dl>
                    </li>
                    <li v-if="highlight.highlightname=='Max Elevation'">
                        <span v-if="highlight.highlightname=='Max Elevation'" class="icon-altitude"></span>
                        <dl v-if="highlight.highlightname=='Max Elevation'">
                            <dt>Max.Altitude</dt>
                            <dd>{{ highlight.highlightvalue }}</dd>
                        </dl>
                    </li>
                </template>
            </ul>
        </div>
    </div>
    <div class="breadcrumb">
        <div class="container">
            <ul>
                <li>
                    <router-link :to="{name:'Home'}">Home</router-link>
                </li>
                <li>
                    <router-link :to="{name:'Packagecategory',params:{slug:breadcrumb.slug}}" v-if="breadcrumb">{{breadcrumb.name}}</router-link>
                </li>
                <li v-if="packagedet"><span class="live">{{packagedet[0].title}}</span></li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col xs-12 sm-8 md-9">
                <article class="packagebox package__overview" v-for="(packageitem,index) in packagedet" :key="index">
                    <h1>{{packageitem.title}}</h1>
                    <div class="packagebox__headline">
                        <h2 class="packagebox__headline-title">Travel Overview</h2>
                    </div>
                    <span class="bundle__desc-rating rating"><span class="text" v-bind:style="'width:' +percentileValue(packageitem.packagerating)+'%'">3.5</span></span>
                    <div class="reviser" v-html="packageitem.tripoverview">

                    </div>
                </article>
                <div class="scrollbox">
                    <ul class="scroller">
                        <li class="scactive">
                            <a href="#scrItinerary">
                                <span class="icon-route"></span>
                                <span class="text">Itinerary</span>
                            </a>
                        </li>
                        <li>
                            <a href="#scrInexc">
                                <span class="icon-squareoff"></span>
                                <span class="text">Inc/Exc</span>
                            </a>
                        </li>
                        <li>
                            <a href="#scrDeparture">
                                <span class="icon-calendar"></span>
                                <span class="text">Departure</span>
                            </a>
                        </li>
                        <li v-if="galleryimages">
                            <a href="#scrMedia">
                                <span class="icon-album"></span>
                                <span class="text">Gallery</span>
                            </a>
                        </li>
                        <li>
                            <a href="#scrEssential">
                                <span class="icon-idea"></span>
                                <span class="text">Info</span>
                            </a>
                        </li>
                        <li>
                            <a href="#scrFaqs">
                                <span class="icon-faq"></span>
                                <span class="text">FAQs</span>
                            </a>
                        </li>
                        <li v-if="reviews">
                            <a href="#scrBquote">
                                <span class="icon-review"></span>
                                <span class="text">Review</span>
                            </a>
                        </li>
                    </ul>
                    <section class="packagebox itinerary" id="scrItinerary">
                        <div class="packagebox__headline">
                            <h2 class="packagebox__headline-title">Itinerary</h2>
                        </div>
                        <div class="itinerary__item" v-for="(itinerary,index) in itineraries" :key="index">
                            <div class="itinerary__item-day">
                                <span class="day">Day <strong>{{itinerary.day}}</strong></span>
                            </div>
                            <div class="itinerary__item-detail">
                                <h3>{{itinerary.itinerarytitle}}</h3>
                                <p v-html="itinerary.itinerarydescription"></p>
                            </div>
                        </div>

                    </section>
                    <section class="packagebox tailored">
                        <div class="tailored__detail">
                            <img :src="urlPath+'uploads/media/package/tailor-package.jpg'" alt="image description">
                            <div class="tailored__detail-title">
                                <h2>Not Planned Yet</h2>
                                <p>Create your own tailored made trip on your own way and get ready to travel.</p>
                            </div>
                            <div class="tailored__detail-action">
                                <!-- <a href="#" class="btn btn-default-outline">Tailored Package</a> -->
                                <router-link v-if="packagedet" :to="{name:'TailormadeQuery',params:{slug:packagedet[0].slug,title:packagedet[0].title}}" class="btn btn-default-outline">Tailor Package</router-link>
                            </div>
                        </div>
                    </section>
                    <section class="packagebox incexc" id="scrInexc" v-if="packagedet">
                        <template v-for="(packageitem,index) in packagedet" :key="index">
                            <div class="packagebox__headline">
                                <h2 class="packagebox__headline-title">Cost Include/Exclude</h2>
                            </div>
                            <div class="incexc__item">
                                <h3 class="packagebox__entitle">What's Included<span class="icon-right"></span></h3>
                                <div v-html="packageitem.costinclude"></div>

                            </div>
                            <div class="incexc__item">
                                <h3 class="packagebox__entitle">What's Not Included<span class="icon-wrong"></span></h3>
                                <div v-html="packageitem.costexclude"></div>

                            </div>
                        </template>
                    </section>
                    <section class="packagebox departure" id="scrDeparture" v-for="(packageitem,index) in packagedet" :key="index">
                        <div class="packagebox__headline">
                            <h2 class="packagebox__headline-title">Join the Group</h2>
                        </div>
                        <div class="departure__info">
                            <p>The given rates are per person with guaranteed departure schedules. If the given dates are not appropriate for you, please feel free to contact us for requesting a new dates, we will gladly personalize the itinerary and schedule of the trip that fits for you.</p>
                        </div>
                        <div class="departure__table">
                            <table>
                                <thead>
                                    <tr>
                                        <td class="sdate">Start Date</td>
                                        <td class="edate">End Date</td>
                                        <td class="status">Availability</td>
                                        <td class="action">Booking</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="tripdates">
                                        <tr v-for="(trip,index) in tripdates" :key="index">
                                            <td class="sdate" data-th="Start Date">{{trip.startdate}}</td>
                                            <td class="edate" data-th="End Date">{{trip.enddate}}</td>
                                            <td class="status" data-th="Availability">{{trip.availability}}</td>
                                            <td class="action" data-th="Booking">
                                                <router-link class="book-link" :to="{name:'Booking', params:{slug:packagedet[0].slug,title:packagedet[0].title,tripdate:trip.startdate,td:trip.startdate}}">Book Now</router-link>
                                            </td>
                                        </tr>
                                    </template>

                                </tbody>
                            </table>
                        </div>
                    </section>

                    <section class="packagebox media" id="scrMedia" v-if="galleryimages">
                        <div class="packagebox__headline">
                            <h2 class="packagebox__headline-title">Photo/Video</h2>
                        </div>
                        <div class="media__album">
                            <ul class="media__album-list">
                                <li v-for="(images,index) in galleryimages" :key="index">
                                    <a :href="urlPath+'uploads/media/package/gallery/'+images.image" class="gallery-item">
                                        <img :src="urlPath+'uploads/media/package/gallery/thumbnail/326245_'+images.image" :alt="images.picturetitle">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section v-if="packagedet" class="packagebox essential" id="scrEssential">
                        <div class="packagebox__headline">
                            <h2 class="packagebox__headline-title">Essential Info</h2>
                        </div>
                        <div v-for="(packageitem,index) in packagedet" :key="index">
                            <div v-html="packageitem.usefulinfo"></div>
                        </div>
                    </section>
                    <faq />
                    <!-- <section v-if="faqs" class="packagebox faqs" id="scrFaqs">
                        <div class="packagebox__headline">
                            <h2 class="packagebox__headline-title">FAQs</h2>
                        </div>
                        <div class="faqs__item" v-for="(faq,index) in faqs" :key="index" v-bind:class="{factive:index==0}">
                            <h3 class="faqs__item-que">{{faq.question}}</h3>
                            <div class="faqs__item-detail" v-bind:class="{hide:index>0}">
                                <div class="reviser" v-html="faq.answer">

                                </div>
                            </div>
                        </div>
                    </section> -->
                    <section v-if="reviews" class="packagebox bquote" id="scrBquote">
                        <div class="packagebox__headline">
                            <h2 class="packagebox__headline-title">Reviews</h2>
                        </div>
                        <blockquote class="bquote__item" v-for="(review,index) in reviews" :key="index">
                            <span class="bquote__item-img">
                                <img :src="urlPath+'uploads/media/testimonial/thumbnail/140140_'+review.image" alt="image description">
                            </span>
                            <span class="bquote__item-text">
                                <cite><strong>{{ review.name }},</strong>{{review.address}}</cite>
                                <span class="rating"></span>
                                <p v-html="review.description"></p>
                            </span>
                        </blockquote>

                    </section>
                </div>
            </div>
            <div class="col xs-12 sm-4 md-3">

                <aside class="infocard" v-if="packagedet">
                    <div v-if="packagedet[0].packagestatus=='Guaranteed'" class="tag tag-success">{{packagedet[0].packagestatus}}</div>
                    <div v-if="packagedet[0].packagestatus=='Limited'" class="tag tag-warning">{{packagedet[0].packagestatus}}</div>
                    <div v-if="packagedet[0].packagestatus=='Private'" class="tag tag-danger">{{packagedet[0].packagestatus}}</div>
                    <span class="infocard__price"><span class="cur">USD $</span>
                        <strong v-if="packagedet[0].discountedprice">{{packagedet[0].discountedprice}}</strong>
                        <strong v-else>{{packagedet[0].regularprice}}</strong>Per Pax</span>
                    <div class="facts__unit">
                        <span class="icon-calendar"></span><span class="facts__unit-text">{{packagedet[0].tripdays}} Days Trip</span>
                    </div>

                    <div class="facts__unit" v-for="(highlight,index) in highlights" :key="index">
                        <span class="icon-accomodation" v-if="highlight.highlightname=='Lodging'"></span>
                        <span class="icon-restro" v-if="highlight.highlightname=='Meal'"></span>
                        <span class="icon-vehicle" v-if="highlight.highlightname=='Vehicle'"></span>
                        <span class="icon-season" v-if="highlight.highlightname=='Season'"></span>
                        <span class="icon-trekker-fill" v-if="highlight.highlightname=='Per day walk'"></span>
                        <span v-if="highlight.highlightname=='Difficulty'"></span>
                        <span v-else-if="highlight.highlightname=='Max Elevation'"></span>
                        <span class="facts__unit-text" v-else>{{highlight.highlightvalue}}</span>
                    </div>

                    <div class="infocard__action">
                        <router-link class="btn btn-default" :to="{name:'Enquire',params:{slug:packagedet[0].slug,title:packagedet[0].title}}">Enquire</router-link>
                        <router-link class="btn btn-success" :to="{name:'Booking', params:{slug:packagedet[0].slug,title:packagedet[0].title,td:this.twoday,tripdate:this.twoday}}">Book Now</router-link>
                    </div>
                </aside>
                <a v-if="packagedet" :href="urlPath+'uploads/media/pdf/'+packagedet[0].pdfdocument" class="btn btn-primary detail-btn-pdf" download>Itinerary PDF Download</a>
                <aside class="discount">
                    <h2 class="discount__title">Group Discount</h2>
                    <dl class="discount__item" v-for="(discount,index) in groupdis" :key="index">
                        <dt>{{ discount.groupsize }}</dt>
                        <dd>USD {{ discount.amount }} PP</dd>
                    </dl>

                </aside>
                <aside class="route" v-if="routemap">
                    <a :href="urlPath+'uploads/media/route/'+packagedet[0].routemap" class="single-item">
                        <img :src="urlPath+'uploads/media/route/'+packagedet[0].routemap" alt="">
                        <h2 class="route__title">Route Map</h2>
                    </a>
                </aside>
                <div class="related" v-if="packagerelatedblog">
                    <h2 class="related__title"><strong>Related</strong> Article</h2>
                    <article v-for="(blogpost,index) in packagerelatedblog" :key="index" class="latest__post">
                        <div class="latest__post-img">
                            <router-link :to="{name:'Blogdetail', params:{slug:blogpost.slug,title:blogpost.title}}"><img :src="urlPath+'uploads/media/blog/thumbnail/440230_'+blogpost.image" alt="blogpost.title"></router-link>

                        </div>
                        <div class="latest__post-desc">
                            <time datetime="2011-06-26" class="latest__post-dated">{{formatDate(blogpost.created_at)}}</time>
                            <h2 class="latest__post-title">
                                <router-link :to="{name:'Blogdetail', params:{slug:blogpost.slug,title:blogpost.title}}">{{blogpost.title}}</router-link>
                            </h2>
                        </div>
                    </article>

                </div>
            </div>
        </div>
    </div>
    <section class="combo-frame" v-if="relatedpackages">
        <div class="container">
            <div class="headline">
                <h2 class="headline__title">Related <span>Package</span></h2>
                <span class="headline__meta">Some similar package you might be interested</span>
            </div>
            <div class="row">
                <div class="col xs-12 sm-6 md-3" v-for="(related,index) in relatedpackages" :key="index">
                    <article class="bundle">
                        <div class="bundle__img">
                            <router-link :to="{name:'Packagedetail', params:{slug:related.slug,title:related.title}}"><img :src="urlPath+'uploads/media/package/thumbnail/326245_'+related.featuredimage" alt="image description"></router-link>
                            <span class="bundle__duration"><strong>{{related.tripdays}}</strong> Days</span>
                        </div>
                        <div class="bundle__desc">
                            <div class="txtWrapper">
                                <h3 class="bundle__desc-title">
                                    <router-link :to="{name:'Packagedetail', params:{slug:related.slug,title:related.title}}">{{ related.title }}</router-link>
                                </h3>
                                <p v-if="related.excerpt">{{truncateText(related.excerpt)}}</p>
                            </div>
                            <span class="bundle__desc-rating rating rating-3"><span class="text">{{related.packagerating}}</span></span>
                            <span class="bundle__desc-price" v-if="related.discountedprice">US ${{related.discountedprice}}</span>
                            <span v-else class="bundle__desc-price">US ${{related.regularprice}}</span>
                        </div>
                    </article>
                </div>

            </div>
        </div>
    </section>
    <featured />
</main>
</template>

<script>
import axios from 'axios';
// import "../assets/js/stickykit.min.js";
import jQuery from 'jquery';
import "../assets/js/jquery.matchHeight.js";
import dayjs from 'dayjs';
import "../assets/js/jquery.magnific-popup.js";

import {
    defineAsyncComponent
} from "@vue/runtime-core";
const Awesomepackage = defineAsyncComponent(() => import( /* webpackChunkName: "Awesomepackage" */ './Homecomponent/Awesomepackage.vue'));
const FAQs = defineAsyncComponent(() => import( /* webpackChunkName: "FAQs" */ './Packagecomponent/FAQs.vue'));
export default {
    name: 'Packagedetail',
    props: ['slug'],
    data() {
        return {
            packagedet: undefined,
            highlights: undefined,
            itineraries: undefined,
            tripdates: undefined,
            // faqs: undefined,
            groupdis: undefined,
            galleryimages: undefined,
            reviews: undefined,
            packagerelatedblog: undefined,
            relatedpackages: undefined,
            apiPath: process.env.VUE_APP_API,
            urlPath: process.env.VUE_APP_PATH,
            breadcrumb: '',
            // slugpath:this.$route.params.slug,
            routemap: '',
            newSlug: '',
            twoday:'',
        }
    },
    async mounted() {
        this.getPackage(this.slug);
        this.getHighlights(this.slug);
        this.getItineray(this.slug);
        this.getTripdate(this.slug);
        // this.getfaqs(this.slug);
        this.getGroupDiscounts(this.slug);
        this.getGalleryImg(this.slug);
        this.getReviews(this.slug);
        this.getPackageRelatedblog(this.slug);
        this.getRelatedpackage(this.slug);
        this.getBreadcrumb(this.slug);

        // if(jQuery(".scroller").length) {
        //     jQuery(".scroller").stick_in_parent({
        //         parent: ".scrollbox",
        //         offset_top: 12
        //     });
        // }

        var d = new Date();
        this.twoday = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate();
        
        
        
        

    },
    methods: {
        getPackage(slugurl) {
            axios.get(this.apiPath + 'showpackage/' + slugurl)
                .then(response => {
                    // console.log(this.$route);
                    //  console.log(response.data)
                    // console.log(response.data[0]['seo_description']);
                    this.packagedet = response.data;
                    this.routemap = response.data[0]['routemap'];
                    const descEl = document.querySelector('head meta[name="description"]');
                    const keyEl = document.querySelector('head meta[name="keywords"]');
                    const titleEl = document.querySelector('head title');

                    descEl.setAttribute('content', response.data[0]['seo_description']);
                    keyEl.setAttribute('content', response.data[0]['seo_keyword']);
                    titleEl.textContent = response.data[0]['seo_title']?response.data[0]['seo_title']:response.data[0]['title'] + " :: Nepal Alternative Treks";
                })

        },
        getHighlights(slugurl) {
            axios.get(this.apiPath + 'gethighlights/' + slugurl)
                .then(highlightresponse => {
                    this.highlights = highlightresponse.data;
                })
        },
        getItineray(slugurl) {
            axios.get(this.apiPath + 'getitineraries/' + slugurl)
                .then(itinerariesresponse => {
                    this.itineraries = itinerariesresponse.data;
                })
        },
        getTripdate(slugurl) {
            axios.get(this.apiPath + 'fixeddeparture/' + slugurl)
                .then(fixeddateresponse => {
                    this.tripdates = fixeddateresponse.data;
                })
        },
        // getfaqs(slugurl) {
        //     axios.get(this.apiPath + 'faqs/' + slugurl)
        //         .then(faqresponse => {
        //             this.faqs = faqresponse.data;
        //         })
        // },
        getGroupDiscounts(slugurl) {
            axios.get(this.apiPath + 'groupdiscount/' + slugurl)
                .then(discountresponse => {
                    this.groupdis = discountresponse.data;
                })
        },
        getGalleryImg(slugurl) {
            axios.get(this.apiPath + 'galleryimage/' + slugurl)
                .then(galleryimgresponse => {
                    this.galleryimages = galleryimgresponse.data;
                })
        },
        getReviews(slugurl) {
            axios.get(this.apiPath + 'relatedreview/' + slugurl)
                .then(reviewresponse => {
                    this.reviews = reviewresponse.data;
                })
        },
        getPackageRelatedblog(slugurl) {
            axios.get(this.apiPath + 'packageRelatedblogs/' + slugurl)
                .then(packageRelatedBlogresponse => {
                    this.packagerelatedblog = packageRelatedBlogresponse.data;
                })
        },
        formatDate(dateString) {
            const date = dayjs(dateString);
            return date.format('DD MMM, YYYY');
        },
        getRelatedpackage(slugurl) {
            axios.get(this.apiPath + 'relatedpackage/' + slugurl)
                .then(relatedpackageresponse => {
                    this.relatedpackages = relatedpackageresponse.data;
                })
        },
        truncateText(value, length = 80) {
            return value.length <= length ?
                value : value.split(' ').slice(0, 12).join(" ") + "..."
        },
        getBreadcrumb(slugurl) {
            axios.get(this.apiPath + 'getpackagecategory/' + slugurl)
                .then(breadcrumbresponse => {
                    this.breadcrumb = breadcrumbresponse.data;
                })
        },
        roundFigure(value) {
            return Math.round(value, -1);
        },
        percentileValue(value) {
            return value * 20;
        },

    },
    async updated() {

        /* GALLERY AND ROUTE POPUP */
        if (jQuery('.gallery-item').length) {
            jQuery('.gallery-item').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        };

        if (jQuery('.single-item').length) {
            jQuery('.single-item').magnificPopup({
                type: 'image'
            });
        };


        /***************************/

        jQuery('.combo-frame .txtWrapper').matchHeight();

        /* SMOOTH SCROLL SEGMENT */

        jQuery('a[href="#"]').click(function (e) {
            e.preventDefault();
        });

        // Cache selectors
        var lastId,
            topMenu = jQuery(".scroller"),
            topMenuHeight = -6,
            // All list items
            menuItems = topMenu.find("a"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function () {
                var item = jQuery(jQuery(this).attr("href"));
                if (item.length) {
                    return item;
                }
            });

        // Bind click handler to menu items
        // so we can get a fancy scroll animation
        menuItems.click(function (e) {
            var href = jQuery(this).attr("href"),
                offsetTop = href === "#" ? 0 : jQuery(href).offset().top - topMenuHeight + 1;
            jQuery('html, body').stop().animate({
                scrollTop: offsetTop
            }, 800);
            e.preventDefault();
        });

        // Bind to scroll
        jQuery(window).scroll(function () {
            // Get container scroll position
            var fromTop = jQuery(this).scrollTop() + topMenuHeight;

            // Get id of current scroll item
            var cur = scrollItems.map(function () {
                if (jQuery(this).offset().top < fromTop)
                    return this;
            });
            // Get the id of the current element
            cur = cur[cur.length - 1];
            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;
                // Set/remove active class
                menuItems
                    .parent().removeClass("scactive")
                    .end().filter("[href='#" + id + "']").parent().addClass("scactive");
            }
        });

        
    },
    watch: {
        
        slug() {
            this.newSlug = this.$route.params.slug;
            this.getPackage(this.newSlug);
            this.getHighlights(this.newSlug);
            this.getItineray(this.newSlug);
            this.getTripdate(this.newSlug);
            // this.getfaqs(this.newSlug);
            this.getGroupDiscounts(this.newSlug);
            this.getGalleryImg(this.newSlug);
            this.getReviews(this.newSlug);
            this.getPackageRelatedblog(this.newSlug);
            this.getRelatedpackage(this.newSlug);
            this.getBreadcrumb(this.newSlug);

        }
    },
    components: {
        'featured': Awesomepackage,
        'faq': FAQs
    }
}
</script>

<style>
.widget--sticky {
    background: #F05382 !important;
    border: 2px solid #F05382 !important;
    color: rgba(255, 255, 255, 0.9) !important;
}
</style>
